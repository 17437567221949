body {
    background: #fafafa;
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    color: #222;
}

label {
    margin: 0;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: dodgerblue;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 12px;
    color: white;
    position: relative;
    top: 2px;
    left: 10px;
}

/* ==========================================================================
navbar text
========================================================================== */
.navbar-default .navbar-brand {
    color: #fff;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.navbar-brand.logo-big {
    font-size: 30px;
}

.logo .logo-img {
    height: 75px;
    display: inline-block;
    margin-right: 40px;
}

.logo .logo-img-small {
    height: 59px;
    display: inline-block;
}
/* ==========================================================================
Yari styles
========================================================================== */

.navbar-default.production, .navbar-default.dev {
    background-color: #c7030a !important;
}

.navbar {
    font-family: "Franklin Gothic", "ITC Franklin Gothic", Arial, sans-serif;
}

.navbar.navbar-small {
    max-height: 75px;
    min-height: 75px;
}

.navbar .navbar-brand {
    height: 84px;
}

.navbar .navbar-brand.navbar-brand-small {
    height: 75px;
}

.navbar-brand.logo span.ng-scope {
    position: relative;
    top: 8px;
    font-size: 30px;
    color: #fff;
    left: 10px;
}

.navbar .navbar-brand:hover span.ng-scope {
    color: #D6EEEC!important;
}

.navbar .navbar-brand a:hover span.ng-scope {
    color: #D6EEEC!important;
}

.navbar-brand.logo.title {
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.nav.navbar-nav.navbar-right li.dropdown.open {
    background: #fff;
    color: #c7030a;
    transition: 0.4s;
}

.navbar .navbar-nav > li > a {
    color: inherit;
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 18px;
    color: #fff;
}

.navbar .dropdown-menu .active > a, .navbar.navbar-default .dropdown-menu .active > a {
    background-color: #fff;
    color: #c7030a;
}

.navbar .dropdown-menu .active > a:hover {
    color: #c7030a;
    background: #eeeeee;
}

.dropdown-menu > li > a, .navbar .dropdown-menu .active > a:hover, .navbar.navbar-default .dropdown-menu .active > a:hover {
    color: #c7030a;
}

.navbar .navbar-nav > .open > a, .navbar .navbar-nav > .open > a:hover, .navbar .navbar-nav > .open > a:focus {
    border: 1px solid #c7030a;
}

.navbar-right .dropdown-menu {
    right: 1px;
    left: auto;
}

.navbar-collapse.production, .navbar-collapse.dev {
    background-color: #c7030a;
}

.navbar-brand-title {
    color: inherit;
}


/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a {
    color: #c7030a;
}

a:hover {
    color: red;
    text-decoration: none;
}

a:hover, .hand, [jh-sort-by] {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}
.point:last {
    margin:0 !important;
}

.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

.row.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .row.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .row.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
    background-color: #fff;
    color: #000 !important;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}

.datetime-picker-dropdown {
    top: -300%;
    left: 50%!important;
}

.form-group.is-focused .form-control {
    background-image: linear-gradient(rgb(244,67, 54), rgb(244,67, 54)), linear-gradient(#D2D2D2, #D2D2D2);
}

label.sedutaDataConvocazione {
    color: rgb(244, 67, 54)!important;
}

label.sedutaDataConvocazione.required:after {
    content: ' *';
    font-size: 13px;
    vertical-align: top; 
}


/* ==========================================================================
Fix error field display
========================================================================== */
.form-group .help-block {
    position: static;
}

/* jhipster-needle-css-add-main JHipster will add new css style */


/* STICKY STUFF */
.sticks {
    display: none;
}
.is-sticky .sticks {
    display: block;
}
.is-sticky .not-sticky {
    display: none;
}

@media (max-width: 767px) {

    .navbar .navbar-nav .open .dropdown-menu > li > a {
        color: inherit;
        padding: 3px 20px;
    }

    .navbar .navbar-nav > li > a {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .navbar .navbar-nav > .open > a, .navbar .navbar-nav > .open > a:hover, .navbar .navbar-nav > .open > a:focus {
        border: 0px;
        border-bottom: 1px solid;
    }

    .dropdown-menu {
        padding: 0px 0;
    }

    .navbar-nav {
        margin: 0px -15px;
    }

    ul.nav.navbar-nav.navbar-right ul.dropdown-menu:nth-last-child(1) li{
        padding: 10px 20px;
    }

    ul.dropdown-menu {
        border-bottom: 1px solid #c7030a!important;
    }

    button.navbar-toggle {
        position: absolute;
        top: 15px;
        right: 0;
    }

}

@media (max-width: 520px) {

    .navbar-brand.logo span.ng-scope {
        display: none;
    }

}

.btn-default:not(.atto-list-container .btn-default) {
    background-color: #c7030a;
    color: #fff !important;
}

.btn-default:hover:not(.atto-list-container .btn-default:hover) {
    background-color: red !important;
}

.md-button.md-default-theme.md-primary.md-fab, .md-button.md-primary.md-fab, .md-button.md-default-theme.md-primary.md-raised, .md-button.md-primary.md-raised {
    background-color: #c7030a;
    color: #fff;
}

.md-button.md-default-theme.md-primary.md-fab:hover, .md-button.md-primary.md-fab:hover, .md-button.md-default-theme.md-primary.md-raised:hover, .md-button.md-primary.md-raised:hover {
    background-color: red;
}

.panel-body.slideable {
    padding: 0;
}

.readonly-section {
    color: #444;
}

.md-content.md-default-theme, md-content {
    background-color: transparent;
}

.field-invalid {
    color: white !important;
    font-weight: bold !important;
    background-color: red;
    padding: 5px;
    border-radius: 5px;
}

.checkbox label, .radio label, label, md-input-container.md-input-focused label:not(.md-no-float),
md-input-container.md-input-has-placeholder label:not(.md-no-float), md-input-container.md-input-has-value label:not(.md-no-float) {
    color: rgb(244, 67, 54);
}

legend {
    color: rgb(244, 67, 54);
    font-size: 18px;
}

.gestione-atti-fixed-sidenav {
    position: fixed !important;
    max-width: 50%;
    width: 40%;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.spacer {
    height: 50px;
}

.md-input {
    color: #222 !important;
}

.md-input[disabled] {
    color: #444 !important;
}

md-input-container label:not(.md-no-float):not(.md-container-ignore), md-input-container .md-placeholder {
    /*color: rgb(244, 67, 54);*/
}

.no-margin-bottom {
    margin-bottom: 0px;
}

.send-back-spacer {
    padding-top: 30px;
    padding-bottom: 5px;
}

.send-back-spacer h2 {
    display: flex;
    text-transform: uppercase;
    text-align: center;
    width: 75%;
}

.send-back-spacer h2:after, h2:before {
    content: '';
    height: 2px;
    width: 35%;
    background: red;
    margin: 14px 10px;
}

.notifica-atto-link:hover {
    text-decoration: underline;
}

a.action-inactive {
    color: #444 !important;
}

.advanced-search .md-select-placeholder span {
    color: rgb(244, 67, 54);
}

.atto-detail-button-sidenav {
    font-size: 24px;
    line-height: 1.33;
    min-width: 0;
}

.md-dialog-fullscreen {
    width: 80% !important;
    max-width: 80% !important;
}

.md-dialog-fullscreen md-dialog {
    width: 100% !important;
    max-width: 100% !important;
}

.notifications-search {
    color: #c7030a;
}

#unified-text-message-body md-chip.ng-scope.md-focused {
    background: rgb(224,224,224);
}

.clean-allignment {
    margin-bottom: 0;
    padding-bottom: 0;
}

a.state-navigation:before {
    content: "•"; /* Insert content that looks like bullets */
    padding-right: 8px;
    color: blue; /* Or a color you prefer */
}

.md-autocomplete-suggestions-container {
    z-index: 9999 !important;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
    width: 500px;
}

div.atto-detail-pane {
    margin-top: 10px;
}

div.atto-detail-pane span.pane-title {
    font-size: 2em;
    padding-top: 5px;
}

.atto-detail-pane .section-collapser {
    font-size: 1.6em;
    margin-right: 10px;
}

.rinvio-notice {
    font-size: 1.5em;
}

.bold-text {
    font-weight: bold !important;
    font-size: 1.5em;
}

.small-chip {
    font-size: 12px;
}

.emendment-block md-chip {
    clear: left;
}
.padded-bottom-70 {
    padding-bottom:70px;
}

.striped-row {
    background-color: #e6e6e6;
}

.import-detail-error {
    color: #c7030a;
    font-weight: bold;
}
.import-detail-success {
    color: #00b33c;
    font-weight: bold;
}

/* CUSTOM SPINNER CSS */
.custom-loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #c7030a;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: custom-spin 2s linear infinite;
    margin-left: auto;
    margin-right: auto;
  }

  @keyframes custom-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
/* ================== */

.rep-gen-ml {
    margin-left: 10px;
}

.iter-hyper-link {
    cursor:pointer;
    color:blue;
    text-decoration:none;
}

.iter-hyper-link:hover {
    text-decoration:underline;
}
/* MOBILE UI */
.atto-class-row-item.atto-class-row-item-tipo-atto.label {
    margin-right: 10px;
    position: relative;
    top: 2px;
    display: block;
}

.atto-class-row-item.atto-class-row-item-titolo {
    display: block !important;
    margin: 10px auto 0px;
    font-size: 15px;
}


@media screen and (max-width: 767px) {

button.navbar-toggle {
    z-index: 65999;
	right: 6px!important;
}

.logo .logo-img {
    height: 70px;
    display: inline-block;
    margin-right: 0px;
}

span.navbar-version {
    display: none;
}

#atto-list-container .jh-table > tbody > tr > td {
    width: 100%;
    display: block;
}

#atto-list-container md-input-container, #atto-list-container .layout-row > .flex-15 {
    -webkit-box-flex: 0 !important;
	-ms-flex: none !important;
	flex: none !important;
	max-width: 100% !important;
	margin: 10px 0;
    margin-bottom: 10px;
	display: block;
}

#atto-list-container .layout-row {
    display: block;
}

#atto-list-container i.glyphicon.glyphicon-cog.advanced-search-toggler {
    position: absolute;
    right: 0px;
    top: 20px;
    font-size: 24px;
}

#atto-list-container md-input-container.md-icon-float.flex {
    margin-bottom: -15px;
}

#atto-list-container button.md-fab.md-primary.md-button.md-ink-ripple {
    width: 100%;
    border-radius: 10px;
    margin-left: 0px;
    padding: 0;
    line-height: 30px;
    height: 30px;
	margin-top: -10px;
}

#atto-list-container md-input-container:nth-child(1) {
    margin: 0;
    /* display: none; */
}

.gestione-atti-fixed-sidenav {
    min-width: 100%;
    max-width: 100%;
    display: block;
    padding: 0;
    z-index: 65999;
}

.gestione-atti-fixed-sidenav .col-xs-6 {
    width: 100%;
}

.gestione-atti-fixed-sidenav .col-xs-6 a.btn.btn-danger {
    top: -45px;
    right: 3px;
    z-index: 65999;
}

.gestione-atti-fixed-sidenav .col-xs-6:nth-child(2) {
    height: 0;
}

atto-table-list .flex-30 {
    max-width: 100%;
    flex: none!important;
    margin: 0 18px;
}

.gestione-atti-fixed-sidenav .layout-gt-xs-row.flex:nth-child(2) {
    margin-top: -25px;
}

.gestione-atti-fixed-sidenav .layout-column.flex-offset-5.flex-45 {
    max-width: 100%;
    margin: 0 18px;
}

.gestione-atti-fixed-sidenav .spacer {
    display: none;
}

.gestione-atti-fixed-sidenav .layout-gt-xs-row.flex label {
    margin-bottom: 7px;
}

.navbar.navbar-small {
    max-height: none!important;
}

.hl-sticky.well.well-sm.is-sticky ~ div {
    height: 0!important;
}

.hl-sticky.well.well-sm.is-sticky {
    left: 0!important;
    position: relative!important;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.0), 0 0px 0px 0 rgba(0, 0, 0, 0.0)!important;
    background: inherit!important;
}

thead{
	display: none;
}

.container-fluid .container-fluid {
    padding: 0;
}
.atto-class-row-item.atto-class-row-item-titolo {
    display: block !important;
	margin: 10px auto 0px;
	font-size: 16px;
}

form .layout-xs-column.layout-align-start-start.layout-row {
    max-width: 100%;
    overflow: hidden;
}

.pull-left-space-bottom {
    padding-bottom: 100px !important;
    margin-top: -75px;
}

.md-button.md-icon-button {
    margin: -7px 6px;
}

form .layout-xs-column.layout-align-start-start.layout-row .table > tbody > tr > td {
    padding: 6px !important;
}

.container-fluid .row .col-md-2 {
    padding: 0!important;
    text-align: left;
}

.container-fluid .row {
    margin: 0!important;
}

.container-fluid .row .col-md-2 h2::before {
    display: none;
}

.md-fab.md-info.md-button.md-ink-ripple.atto-detail-button-sidenav {
    z-index: 65999;
}

.container-fluid .hide-xs:not(.show-xs):not(.show) {
    display: block!important;
    position: relative!important;
    top: -85px!important;
    left: 10px!important;
    padding: 0!important;
}

.container-fluid .hide-xs:not(.show-xs):not(.show) .md-button-group {
    display: block!important;
}

.container-fluid .hide-xs:not(.show-xs):not(.show) button {
    width: 47%;
    display: inline-block;
    margin: 10px 2% 10px 1%;
}

div.row ~ div label.label {
    margin-left: 0px;
}

#commission-state-0 a.section-collapser {
    margin-left: 10px;
    margin-bottom: 30px;
}

atto-table-list .btn.btn-primary.btn-sm {
    display: none;
}

.container-fluid .container-fluid .row .col-md-2 {
    padding: 0 15px !important;
}

.btn-group.flex-btn-group-container {
    justify-content: center;
}

.table-striped > tbody > tr {
    border: 2px solid gray;
    margin-top: 2px;
    display: block;
}

.row.tree-header .flex-offset-5 {
    margin-left: 0;
}

.container-fluid h1 {
    display: none;
 }

.md-icon-float.flex-15 {
    height: 36px;
}

.jh-table.table.table-striped {
    margin-bottom: 0;
}

.col-md-4 h2 {
    margin-top: 0!important;
}

.gestione-atti-fixed-sidenav .col-xs-6 .flex-offset-5 {
    margin-left: 5px !important;
}

.atto-class-row-item.atto-class-row-item-tipo-atto.label {
    margin-right: 10px;
    position: relative;
    top: 2px;
}

.btn.btn-sm, .input-group-btn .btn.btn-sm, .btn-group-sm .btn, .btn-group-sm .input-group-btn .btn {
    font-size: 18px!important;
	padding: 5px 18px!important;
}

.col-md-2 h2 {
    margin-top: 0!important;
}

.rotella-burger-open {
    top: 0;
}

.container-fluid #atto-list-container form md-input-container.md-input-has-placeholder.md-input-has-value {
    width: 85%!important;
 }

.navbar-header .primary.md-button.md-ink-ripple {
    float: right;
    font-size: 18px;
    right: 40px;
    top: 17px !important;
}

.layout-xs-column.layout-align-start-start.layout-row .md-datepicker-input-container, .layout-xs-column.layout-align-start-start.layout-row .md-datepicker-button.md-icon-button.md-button.md-ink-ripple {
    display: block;
}

.layout-xs-column.layout-align-start-start.layout-row .md-datepicker-input-container {
    margin-top: 30px;
    margin-left: -42px;
}

form .layout-xs-column.layout-align-start-start.layout-row .table > tbody > tr > td md-input-container.md-input-has-value {
    display: block;
    margin-left: -15px;
}

.layout-xs-column.layout-row md-input-container {
    margin: 15px 0;
}


}



@media screen and (min-width: 769px) and (max-width: 1400px){

.container-fluid .container-fluid .col-md-8, .container-fluid .container-fluid .col-md-4 {
    width: 100%;
}

}

/*FINE MOBILE UI*/

.order-chip-arrow {
    margin: 0 !important;
    position: relative !important;
    bottom: 2px !important;
    padding: 0 !important;
    width: 30px !important;
}

.home-prom-date-clear-button {
    margin: 0px !important;
    position: relative;
    top: 15px;
    right: 10px;
}

@media screen and (max-width: 767px) {
    .home-prom-date-clear-button {
        margin: 0px !important;
        position: relative;
        top: 0px;
        right: 0px;
    }
}

.tooltip-multiline {
    height: auto;
    font-size: medium;
}


/* ==========================================================================
Sedute
========================================================================== */

.label.label-seduta-A {
    background-color: #9e9e9e;;
}

.label.label-seduta-P {
    background-color: #9e9e9e;;
}

.label.label-seduta-X {
    background-color: #9e9e9e;;
}


/* ==========================================================================
ODG
========================================================================== */

.label.label-odg-A {
    background-color: #9e9e9e;;
}

.label.label-odg-P {
    background-color: #9e9e9e;;
}

.label.label-odg-X {
    background-color: #9e9e9e;;
}


.atto-class-TODO > table > tr {
    background-color: #4DD0E1;
}
.pdl-state-approved{
    background-color: #AED581;
}
.pdl-state-rejected{
    background-color: #FF8A65;
}

.toolbar-sticky{
    background-color: #009688;
}

/* Back to top styles*/

.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1;
    width: 50px;
    height: 50px;
    background: brown;
    opacity: 1;
}

.back-to-top:hover {
    opacity: 1;
    cursor: pointer;
}

.advanced-search-toggler {
    top: 8px;
    color: #c7030a;
}

.advanced-search-toggler:focus {
    text-decoration: none;
    color: #c7030a;
}

.action-button-fixed-bottom{
    position: fixed;
    bottom: 0;
    width: 95%;
}

.pull-left-space-bottom{
    padding-bottom: 60px;
}

.label.label-PDL {
    background-color: #009688;
}

.label.label-PDD {
    background-color: #1E88E5;
}

.label.label-PLIP {
    background-color: #607D8B;
}

.label.label-PDLP {
    background-color: #9e9e9e;
}

.label.label-PDLS {
    background-color: #dc00ea;
}

.label.label-legge {
    font-size: 15px;
    background-color: rgb(255,171,64);
    color: black;
}

.document-toolbar .btn-file {
    position: relative;
    overflow: hidden;
    background-color: #CECECE;
}

.document-toolbar .btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.document-toolbar .end-group-btn{
    color:white !important;
}

.document-toolbar .end-group-btn:hover{
    color:lightgrey !important;
}

.document-toolbar .end-group-row{
    background-color: #bbb !important;
    color: white !important;
}

.document-toolbar .header-row{
    background-color: #c7030a !important;
    color: white !important;
}

.document-toolbar .drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    text-align: center;
    padding: 50px 10px;
    margin-left: 10px;
}

.document-toolbar .spacer {
    height: 50px;
}

.document-toolbar .document-toolbar-buttons a {
    padding: 5px;
}

.document-toolbar .document-toolbar-central-block {
    margin-top: 10px;
}

.document-toolbar body.modal-open .md-datepicker-calendar-pane  {
    z-index: 1200 !important;
}

.document-toolbar .table-striped label {
    color: rgba(0, 0, 0, 0.6);
    padding-top: 5px;
}

.document-toolbar .protocol-now-progress {
    display: inline;
    margin-left: 5px;
}

.commission-opinion-block {
    margin-bottom: 20px;
}

.commission-opinion-editable {
    border: 1px solid rgba(135,135,135,1);
    border-radius: 5px;
    padding: 20px;
    -webkit-box-shadow: -4px 6px 5px 0px rgba(135,135,135,1);
    -moz-box-shadow: -4px 6px 5px 0px rgba(135,135,135,1);
    box-shadow: -4px 6px 5px 0px rgba(135,135,135,1);
}

.commission-opinion-title {
    color: rgb(244, 67, 54);
}

.addable-action {
    padding: 2px;
    margin-bottom: 8px;
}

/* Notification component styles */

.notification-grey{
    background-color: grey !important;
}

.notification-red{
    background-color: #c7030a !important;
}
.notification-button{
    height:  4em;
    max-height:   4em;
    width: 1em;
    max-width: 1em;
    position: relative;
}
.notification-button-large{
    height:  8em;
    max-height: 8em;
    width: 1em;
    max-width: 1em;
    position: relative;
}

.transformable {
    -webkit-transition: height 50ms linear;
    -moz-transition: height 50ms linear;
    -o-transition: height 50ms linear;
    -ms-transition: height 50ms linear;
    transition: height 50ms linear;

}

.notification-container-large {
    max-height:8em;
    height:8em;
}

.notification-container{
    max-height: 4em;
    overflow: hidden;
}


/**
 Gestione notifiche aperte/chiuse e lette/non lette
 */

.notification-list-item {
    padding: 0 !important;
}

.notification-body{
    padding: 0.5em 1.5em;
}

.notification-body.open p.mb-1 {
    max-height: 18em;
}

.glyphicon.unread {
    color: red;
    font-size: larger;
}

.notification-body:hover {
    cursor: pointer;
    background-color: rgba(244, 165, 127, 0.5);
}




.archive-tree-row {
    cursor: pointer;
}

.archive-tree-row:hover {
    background-color: rgba(255, 0, 0, 0.1);
}

.tree-header {
    width: 100%;
}

.archive-tree-close {
    font-size: 180%;
}

.archive-tree-leaf {
    overflow: hidden;
}

button.archive-tree-folder span {
    color: #C7030A;
}

a.archive-tree-folder span {
    color: #C7030A;
}

a.archive-tree-folder span.archive-tree-file {
    color: #FFAB40;
}

button.archive-tree-folder span.archive-tree-file {
    color: #FFAB40;
}
.section-notes-input textarea {
    background-color: white;
    border: 1px solid #444 !important;
}

.section-notes-input md-input-container label:not(.md-container-ignore) {
    bottom: 98%;
}

.doc-toolbar-button-icon {
    padding: 5px;
}

.invalid-input {
    font-size: 12px;
    line-height: 14px;
    color: rgb(221,44,0);
}

a:visited.section-links-action, a:focus.section-links-action {
    text-decoration: none;
    color: #c7030a;
}

.btn-add {
    color: #32936F;
}

.btn-test {
    color: #CF995F;
}

.btn-cancel {
    color: #C7030A;
}

.emendment-block {
    padding: 20px 0px 20px 20px;
    border-left: 3px solid rgba(0,0,0,0.38);
    border-radius: 20px;
    margin-bottom: 30px;
}

.emendment-block-first {
    margin-top: 30px;
}